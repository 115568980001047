import {
  InfraBreadcrumbs,
  InfraCol,
  InfraDatepicker,
  InfraGrid,
  InfraIcons,
  InfraLabel,
  InfraModal,
  InfraTable,
  InfraTableColumn,
  InfraTag,
  InfraTypography,
} from '@infralabs/design-system';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from '../../../Helper';
import { ImportQueue } from '../../../Model';
import { ImportQueueRepository } from '../../../Repository/ImportQueueRepository';
import { ImportStatusValue } from '../../../common';
import {
  AlertModal,
  AutocompleteSellers,
  Button,
  Paginate,
  SelectImportStatus,
  SelectImportTypes,
  useAccount,
} from '../../../components';
import { AutocompleteChannels } from '../../../components/AutocompleteChannels';
import { Box, Flex } from '../../../components/Layout';
import useDidMountEffect from '../../../hooks/use-did-mount-effect';
import { WrapperModalConfirm } from '../../styles/style-wrapper-modal';
import { ModalCanceldImport } from './modalCanceledImport';
import { ModalImportPrices } from './modalImportPrice';
import usePricesImportPaginate from './usePricesImportPaginate';

const WrapperModalUpload = styled.div`
  .modal-body {
    padding: 1.5rem 2.5rem 2.5rem 2.5rem;
  }
`;

const importRepository = new ImportQueueRepository();
export const ImportPricesComponent = ({ locationUrl = '' }: { locationUrl: string }) => {
  const history = useHistory();
  useEffect(() => {
    if (locationUrl != '') {
      history.push(locationUrl);
    }
  }, [locationUrl]);

  const { t } = useTranslation();
  const [sellerId, setSellers] = useState('');
  const [channel, setChannel] = useState(localStorage.getItem('ifc-channel-id') ?? '');
  const [importType, setImportType] = useState(null);
  const [initDate, setInitDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [importStatus, setImportStatus] = useState(null);
  const [importQueueSelected, setImportQueueSelected] = useState<ImportQueue>();
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [order, setOrder] = useState('importStatus.id');
  const [modalCanceled, setModalCanceled] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [contextAlert, setContexAlert] = useState<{
    context: string;
    type: 'success' | 'failed' | 'both';
    messages?: any;
  }>({ context: '', type: 'success' });
  const [notClosed, setNotClosed] = useState(true);
  const { currentTenant } = useAccount();

  const links = [
    {
      text: t('app'),
      href: '/',
      disabled: true,
    },
    {
      text: t('price.import.title'),
      disabled: false,
    },
  ];

  const params = useMemo(
    () => ({
      pageSize,
      sellerId,
      channel,
      importType,
      importStatus,
      initDate,
      endDate,
      order,
      currentPage,
    }),
    [pageSize, sellerId, channel, importType, importStatus, initDate, endDate, order, currentPage],
  );
  const { refetch: loadImportQueue, data, paging } = usePricesImportPaginate(params);

  useDidMountEffect(() => {
    setShowModalSuccess(false);
    setModalCanceled(false);
    setOpenModalUpload(false);
    setImportQueueSelected(null);
  }, [currentTenant]);

  const renderBreadcrumb = () => {
    return (
      <Box>
        <InfraBreadcrumbs links={links} componentLink={Link} />
      </Box>
    );
  };
  const renderTitle = () => {
    return (
      <div>
        <InfraTypography variant={'h1_small'} weight={'bold'}>
          {t('price.import.title')}
        </InfraTypography>
      </div>
    );
  };

  const handlePagination = (event, value) => {
    setCurrentPage(value);
  };

  const handlePageSize = (pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const renderPagination = () => {
    return (
      <Paginate
        onChangePageSize={handlePageSize}
        onChangePage={handlePagination}
        totalPages={paging?.totalPages}
        currentPage={currentPage}
      />
    );
  };

  const handleSellers = (id, value) => {
    setLoading(true);
    setSellers(id);
    setLoading(false);
  };

  const handleChannel = (channel) => {
    setCurrentPage(1);
    setChannel(localStorage.getItem('ifc-channel-id') ?? channel?.id);
  };

  function handleImportType(data) {
    setCurrentPage(1);
    setImportType(data.value === -1 ? '' : data.value);
  }

  function handleStatus(data) {
    setImportStatus(data.value === -1 ? '' : data.value);
  }

  function handlePeriod(value) {
    if (Array.isArray(value)) {
      setInitDate(new Date(value[0]).toISOString());
      setEndDate(new Date(value[1]).toISOString());
    } else {
      setInitDate(new Date(value).toISOString());
    }
  }

  function isClean(data) {
    if (data) {
      setInitDate('');
      setEndDate('');
    }
  }

  function renderTypographyButton(message: string) {
    return (
      <InfraTypography variant={'h5_small'} weight={'bold'} color={Colors.neutral_400}>
        {message}
      </InfraTypography>
    );
  }

  const renderToolbar = () => {
    return (
      <Flex justify={'space-between'}>
        <Flex gap="8px" direction="row" grow="0" shrink="1" basis="auto">
          <Box d={'none'}>
            <AutocompleteChannels label="Channel" onChange={handleChannel} />
          </Box>
          <Box w="200px">
            <InfraLabel color="neutral_600">{t('price.fields.autocomplete.seller.label')}</InfraLabel>
            <AutocompleteSellers
              versionId="1"
              onChange={handleSellers}
              channelId={channel}
              placeholder={t('price.fields.autocomplete.seller.placeholder')}
            />
          </Box>

          <Box w="330px">
            <InfraLabel color="neutral_600">{t('price.import.fields.datepicker.label')}</InfraLabel>
            <InfraDatepicker
              maskDate="dd/MM/yyyy, HH:mm"
              onCleared={isClean}
              height="small"
              subtractYears={10}
              placeholder={t('price.import.fields.datepicker.placeholder')}
              period={true}
              value={''}
              onChange={(value) => handlePeriod(value)}
              theme="light"
              setHours={true}
            />
          </Box>
          <Box w="200px">
            <InfraLabel color="neutral_600">{t('price.import.fields.importType.label')}</InfraLabel>
            <SelectImportTypes
              onChange={handleImportType}
              onFetched={() => {}}
              optionFilterDefault={true}
              placeholder={t('price.import.fields.importType.placeholder')}
            />
          </Box>
          <Box w="200px">
            <InfraLabel color="neutral_600">{t('price.import.fields.status.label')}</InfraLabel>
            <SelectImportStatus
              placeholder={t('price.import.fields.status.placeholder')}
              onChange={handleStatus}
              onFetched={() => {}}
              optionFilterDefault={true}
            />
          </Box>
        </Flex>
        <Flex direction="row" justify={'end'} align={'center'} mt={'25px'}>
          <Button
            color="neutral"
            size="large"
            outline={false}
            icon="UploadSimple"
            iconRight={true}
            onClick={() => setOpenModalUpload(true)}
          >
            {renderTypographyButton(t('price.import.button.upload'))}
          </Button>
        </Flex>
      </Flex>
    );
  };

  const renderFilterIcon = () => {
    return (
      <span style={{ marginInline: '10px' }}>
        <InfraIcons name="SortDescending" size={16} weight="bold" />
      </span>
    );
  };

  function renderImportType(data) {
    return new Map([
      ['PRODUCT', t('price.import.fields.table.body.importType.product')],
      ['SKU', t('price.import.fields.table.body.importType.sku')],
      ['EXTERNAL_SKU', t('price.import.fields.table.body.importType.externalSku')],
    ]).get(data?.importTypeName);
  }

  function renderStatus(data) {
    switch (data.status) {
      case ImportStatusValue.waitingForProcessing: {
        return (
          <InfraTag
            color="change"
            name={t('price.import.fields.tag.waitingForProcessing')}
            outline={false}
            borde={false}
          />
        );
      }
      case ImportStatusValue.processing: {
        return <InfraTag color="draft" name={t('price.import.fields.tag.Processing')} outline={false} borde={false} />;
      }
      case ImportStatusValue.canceled: {
        return (
          <InfraTag color="errorDefault" name={t('price.import.fields.tag.calledOff')} outline={false} borde={false} />
        );
      }
      case ImportStatusValue.finished: {
        return <InfraTag color="active" name={t('price.import.fields.tag.finished')} outline={false} borde={false} />;
      }
      case ImportStatusValue.invalidFormat: {
        return (
          <InfraTag color="inactive" name={t('price.import.fields.tag.invalid_format')} outline={false} borde={false} />
        );
      }
      case ImportStatusValue.corruptedFile: {
        return (
          <InfraTag color="change" name={t('price.import.fields.tag.corrupted_file')} outline={false} borde={false} />
        );
      }
      case ImportStatusValue.emptyFile: {
        return (
          <InfraTag color="errorDefault" name={t('price.import.fields.tag.empty_file')} outline={false} borde={false} />
        );
      }
    }
  }

  function handleDelete(data) {
    setImportQueueSelected(data);
    setModalCanceled(true);
  }

  function renderDeleteRowTable(data: any) {
    if (data.status === 1) {
      return (
        <a onClick={() => handleDelete(data)}>
          <InfraIcons name="TrashSimple" weight="bold" size={16} />
        </a>
      );
    }
  }

  async function handleDownload(data) {
    if (data.guid) {
      const response = await importRepository.setChannelId(data?.channel?.[0]?.id).getResultDownload('1', data.guid);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `template_${data.guid}.xlsx`);
      document.body.appendChild(link);
      link.click();
      setShowModalSuccess(false);
    }
  }

  function renderDownloadRowTable(data: any) {
    if (data.status === 4) {
      return (
        <a onClick={() => handleDownload(data)}>
          <InfraIcons name="DownloadSimple" weight="bold" size={16} />
        </a>
      );
    }
  }

  function renderModalAlert(): string {
    let message: string = null;
    if (contextAlert.type === 'success') {
      message = t('price.import.alert.success');
    } else if (contextAlert.type === 'failed') {
      message = t('price.import.alert.failed');
    } else {
      message = t('price.import.alert.undefined');
    }
    return message;
  }

  const renderDataTable = () => {
    return (
      <InfraTable value={data} loading={loading} messageEmptyData={t('emptyTable')}>
        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'import.seller' ? '' : 'import.seller')}
          field="seller"
          header={t('price.import.fields.table.columnHeaders.seller')}
          headerAlign="left"
        >
          {order === 'import.seller' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'importType.name' ? '' : 'importType.name')}
          field="importTypeName"
          header={t('price.import.fields.table.columnHeaders.importTypeName')}
          headerAlign="left"
          body={renderImportType}
        >
          {order === 'importType.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          field="totalProccess"
          header={t('price.import.fields.table.columnHeaders.processed')}
          headerAlign="left"
        />

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'import.totalSuccess' ? '' : 'import.totalSuccess')}
          field="totalSuccess"
          header={t('price.import.fields.table.columnHeaders.totalSuccess')}
          headerAlign="left"
        >
          {order === 'import.totalSuccess' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'import.totalError' ? '' : 'import.totalError')}
          field="totalError"
          header={t('price.import.fields.table.columnHeaders.error')}
          headerAlign="left"
        >
          {order === 'import.totalError' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'import.createdAt' ? '' : 'import.createdAt')}
          field="createdAt"
          header={t('price.import.fields.table.columnHeaders.createdAt')}
          headerAlign="left"
          body={({ createdAt }) => (createdAt !== null ? format(new Date(createdAt), 'dd.MM.yyyy HH:mm:ss') : '')}
        >
          {order === 'import.createdAt' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'import.cancelatedAt' ? '' : 'import.cancelatedAt')}
          field="cancelatedAt"
          header={t('price.import.fields.table.columnHeaders.cancelatedAt')}
          headerAlign="left"
          body={({ cancelatedAt }) =>
            cancelatedAt !== null ? format(new Date(cancelatedAt), 'dd.MM.yyyy HH:mm:ss') : ''
          }
        >
          {order === 'import.cancelatedAt' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'importStatus.id' ? '' : 'importStatus.id')}
          field="status"
          body={renderStatus}
          header={t('price.import.fields.table.columnHeaders.status')}
          style={{ 'white-space': 'nowrap' }}
          headerAlign="left"
          width="233px"
        >
          {order === 'importStatus.id' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn header="" headerAlign="left" padding="checkbox" body={renderDeleteRowTable} />

        <InfraTableColumn header="" headerAlign="left" padding="checkbox" body={renderDownloadRowTable} />
      </InfraTable>
    );
  };

  return (
    <>
      <InfraGrid>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'31px'}>{renderBreadcrumb()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'28px'}>{renderTitle()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'22px'}>{renderToolbar()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'-10px'}>{renderDataTable()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt="3px" mb="64px">
            {renderPagination()}
          </Box>
        </InfraCol>
      </InfraGrid>
      <WrapperModalUpload>
        <InfraModal
          title={t('importPrice.modalUpload.title')}
          show={openModalUpload}
          onClose={() => setOpenModalUpload(false)}
          closed={setNotClosed}
        >
          <Box mt="32px">
            <Flex gap="20px" direction="column">
              <ModalImportPrices
                onCloseModal={() => {
                  loadImportQueue();
                  setOpenModalUpload(false);
                }}
                onClosed={(value) => setNotClosed(value)}
              />
            </Flex>
          </Box>
        </InfraModal>
      </WrapperModalUpload>

      <WrapperModalConfirm>
        <InfraModal
          theme="light"
          show={modalCanceled}
          onClose={() => {
            setModalCanceled(false);
            setImportQueueSelected(null);
          }}
        >
          <ModalCanceldImport
            onCloseModal={({ result }) => {
              setModalCanceled(false);
              if (result) {
                loadImportQueue();
                setShowModalSuccess(true);
                setContexAlert({ context: 'delete', type: result });
              }
              setImportQueueSelected(null);
            }}
            body={importQueueSelected}
          />
        </InfraModal>
      </WrapperModalConfirm>

      <AlertModal
        message={renderModalAlert()}
        show={showModalSuccess}
        onClose={() => setShowModalSuccess(false)}
        onConfirmModal={() => setShowModalSuccess(false)}
      />
    </>
  );
};
